import React from 'react'
import './InitialRegistration.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGeolocated } from 'react-geolocated';
import OtpValidation from './OtpValidation';
import { useNavigate } from 'react-router-dom';

function InitialRegistration() {
    const { coords, getCurrentPosition } = useGeolocated();  // Catching the Location of the client
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
          name: '',
          email: '',
          mobile: '',
          address: '',
          city : '',
          country: '',
          location: '',
          dropdown: ''
        },

        validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      mobile: Yup.string().required('Required'),
      address: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      postalCode: Yup.number().required('Required'),
      country: Yup.string().required('Required'),
      location: Yup.string(),
      dropdown: Yup.string().required('Required')
    }),

    onSubmit: values => {
        alert(' Registration Form submitted successfully! Click OK to verify your OTP');
        navigate('/otp_verification')
        console.log(values);
      },
    });

    const handleLocationClick = () => {
        if (getCurrentPosition) {
          getCurrentPosition();
        }
      };



  return (
    <div className="registration-container">
    <form onSubmit={formik.handleSubmit} className="registration-form">
    <div className="registration-header">
    <img className="login-image" src = {`${process.env.PUBLIC_URL}/asset/AuthImage/bitss_icon_1.png`} alt = "logo" />
      <h2 className="registration-h2">AUTHENTICATOR REGISTRATION FORM</h2>
      
      </div>
      <div className="form-group">
        <label htmlFor="name">Name *</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder='Enter your full name'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="error">{formik.errors.name}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="email">Email *</label>
        <input
          type="email"
          placeholder='Enter your email'
          id="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="mobile">Mobile *</label>
        <input
          type="text"
          placeholder='Enter your mobile number'
          id="mobile"
          name="mobile"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.mobile}
        />
        {formik.touched.mobile && formik.errors.mobile ? (
          <div className="error">{formik.errors.mobile}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="address">Address *</label>
        <input
          type="text"
          placeholder='Enter your address'
          id="address"
          name="address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.address}
        />
        {formik.touched.address && formik.errors.address ? (
          <div className="error">{formik.errors.address}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="city">City *</label>
        <input
          type="text"
          placeholder='Enter your city'
          id="city"
          name="city"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.city}
        />
        {formik.touched.city && formik.errors.city ? (
          <div className="error">{formik.errors.city}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="postalCode">Postal Code *</label>
        <input
          type="number"
          placeholder='Enter your Postal Code'
          id="postalCode"
          name="postalCode"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.postalCode}
        />
        {formik.touched.postalCode && formik.errors.postalCode ? (
          <div className="error">{formik.errors.postalCode}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="country">Country *</label>
        <input
          id="country"
          placeholder='Enter your Country'
          name="country"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.country}
        >
          {/* Add more countries as needed */}
        </input>
        {formik.touched.country && formik.errors.country ? (
          <div className="error">{formik.errors.country}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="location">Location</label>
        <input
          type="text"
          id="location"
          placeholder='Allow access to your location'
          name="location"
          readOnly
          value={coords ? `Lat: ${coords.latitude}, Lon: ${coords.longitude}` : formik.values.location}
        />
        <button type="button" onClick={handleLocationClick}>Get Location</button>
      </div>

      <div className="form-group">
        <label htmlFor="dropdown">Registration Type *</label>
        <select
          id="dropdown"
          name="dropdown"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dropdown}
        >
          <option value="">Select an Option</option>
          <option value="option1">Individual Registration</option>
          <option value="option2">Company Registration</option>
          <option value="option3">Govermental Registration</option>
        </select>
        {formik.touched.dropdown && formik.errors.dropdown ? (
          <div className="error">{formik.errors.dropdown}</div>
        ) : null}
      </div>

      <button type="submit">Submit</button>
    </form>
  </div>
  )
}

export default InitialRegistration

