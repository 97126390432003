import React, { useState } from 'react';
import './Login.css';
import { getImageUrl } from '../../GetImageURL';
import { useNavigate } from 'react-router-dom';



const Login = () => {   ///Function for catching and updating user entering credentials

const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Username:', username);
    console.log('Password:', password);
}

  return (
    <div className="login-container">
    {console.log(getImageUrl("AuthImage/bitss_icon_1.png"))}
    
    

      <div className="login-box">
      <img className="login-image" src = {`${process.env.PUBLIC_URL}/asset/AuthImage/bitss_icon_1.png`} alt = "logo" />
        <h1  className="login-header"> AUTNENTICATOR</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            required
          />
          <button type="submit" className="login-button">Log In</button>
          <a className="footer-links" href="#forgot-password">Forgot password?</a>
          <a  className="footer-links"  onClick={()=>navigate('/registration')} >Click Here to Register</a>
        </form>
        {/* <div className="footer-links">
          <a href="#forgot-password">Forgot password?</a>
          <a href="#sign-up">Sign up</a>
        </div> */}
      </div>

      <div className="footer">
        <a href="https://bitss.fr/">BITSS Authenticator</a>
        <a href="https://bitss.fr/">Plans</a>
        <a href="https://bitss.fr/">Help</a>
        <a href="https://bitss.fr/general-condition/">About US</a>
        <a href="https://bitss.fr/privacy-policy">Privacy</a>
        <a href="https://bitss.fr/contact-us/">Contact Us</a>
        <p>© 2024 authentication.bitss.fr |  ALL RIGHTS RESERVED</p>
      </div>
      
    </div>
  );
};

export default Login;