import logo from './logo.svg';
import './App.css';
import AppRoutes from './Routes/AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import InitialRegistration from './Components/Register/InitialRegistration/InitialRegistration';
import OtpValidation from './Components/Register/InitialRegistration/OtpValidation';

function App() {
  return (
    <BrowserRouter>
    
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
