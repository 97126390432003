import React, { useState, useEffect,  } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';
import './OtpValidation.css'
import DetailedRegistration from '../Detailed Registration/DetailedRegistration';

function OtpValidation() {

 
let navigate = useNavigate();

  const [otpEmail, setOtpEmail] = useState('');
  const [otpMobile, setOtpMobile] = useState('');
  const [isOtpSubmitted, setIsOtpSubmitted] = useState(false);
  const [canResendOtp, setCanResendOtp] = useState(true);
  const [resendTimer, setResendTimer] = useState(180); // 3 minutes in seconds

  useEffect(() => {
    let timer;
    if (!canResendOtp) {
      timer = setInterval(() => {
        setResendTimer(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            setCanResendOtp(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [canResendOtp]);

  const handleOtpSubmit = (event) => {
    event.preventDefault();
    // Handle OTP submission logic here
    console.log(`Mobile otp is ${otpMobile} and Email otp is ${otpEmail}`)
    setIsOtpSubmitted(true);
  };

  const handleResendOtp = () => {
    if (canResendOtp) {
      // Handle OTP resend logic here
      setCanResendOtp(false);
      setResendTimer(180); // Reset timer for 3 minutes
    }
  };

  const handleAfterSubmitButton= ()=>{
      navigate('/registration2')
  }

  return (
    <div className="otp-container">
    <div className="otp-card">
      <h2> <img width={"80px"} src={`${process.env.PUBLIC_URL}/asset/AuthImage/bitss_icon_1.png`} alt =" bitss logo"  />OTP Validation</h2>
      {!isOtpSubmitted ? (
        <form onSubmit={handleOtpSubmit}>
          <div className="input-group">
            <label>Email OTP</label>
            <input
              type="text"
              value={otpEmail}
              onChange={(e) => setOtpEmail(e.target.value)}
              placeholder="Enter email OTP"
              required
            />
          </div>
          <div className="input-group">
            <label>Mobile OTP</label>
            <input
              type="text"
              value={otpMobile}
              onChange={(e) => setOtpMobile(e.target.value)}
              placeholder="Enter mobile OTP"
              required
            />
          </div>
          <button type="submit" className="submit-button">Submit</button>
          <button
        onClick={handleResendOtp}
        disabled={!canResendOtp}
        className="resend-button"
      >
        {canResendOtp ? 'Resend OTP' : `Resend OTP in ${Math.floor(resendTimer / 60)}:${resendTimer % 60}`}
      </button>
        </form>

        
        
      ) : (
        <>
        <p>OTP Verification successfull</p>
        <button
        onClick={handleAfterSubmitButton}
        // disabled={!canResendOtp}
        className="resend-button"
      >
       Complete Your Registration
      </button>
        </>
       
        
      )}
      {/* <button
        onClick={handleResendOtp}
        disabled={!canResendOtp}
        className="resend-button"
      >
        {canResendOtp ? 'Resend OTP' : `Resend OTP in ${Math.floor(resendTimer / 60)}:${resendTimer % 60}`}
      </button> */}
    </div>
  </div>
  )
}

export default OtpValidation