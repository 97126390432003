import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Login from '../Components/Login/Login';
import InitialRegistration from '../Components/Register/InitialRegistration/InitialRegistration';
import OtpValidation from '../Components/Register/InitialRegistration/OtpValidation';
import DetailedRegistration from '../Components/Register/Detailed Registration/DetailedRegistration';

function AppRoutes() {
  return (
    <Routes>
      <Route path= "/" element=<Login /> />
      <Route path = "/registration" element = <InitialRegistration /> />
      <Route path = "/otp_verification" element=<OtpValidation /> />
      <Route path= "/registration2" element= < DetailedRegistration /> />

    </Routes>
  )
}

export default AppRoutes